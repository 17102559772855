function About(){

    return (
        <div className="container" id="about-container">
        <h4 className="about-bio" >We are a traders market for luxury clothing and goods. Bringing eco-consciousness and fashionability together under one roof, with a vision for a cleaner tomorrow.</h4>
        </div>
    )

}

export default About;